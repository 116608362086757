import React from "react"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
const TermsOfUse = () => {
  return (
    <>
      <Seo
        title={`QUICK GLOBAL MEXICO, S.A. DE C.V. 人材登録利用規約 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container termsofuse">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="termsofuse__about-security">
              <SectionTitle>
                QUICK GLOBAL MEXICO, S.A. DE C.V. 人材登録利用規約
              </SectionTitle>
              <hr className="u-line-bottom" />
              <p>
                QUICK GLOBAL MEXICO, S.A. DE
                C.V.（以下「当社」という）が提供する人材紹介サービスのお申し込みにあたり以下の利用規約（以下、本規約）についてご理解、ご承諾の上でお申し込みいただきますよう宜しくお願いいたします。
              </p>
              <h2>第１条（人材紹介サービス）</h2>
              <p>
                人材紹介サービス（以下「本サービス」という）とは、当社が提供する以下のサービスおよびその他関連サービスのことをいいます。
              </p>

              <ol>
                <li>
                  人材紹介サポートサービス
                  利用者（求職者）からのお申し込み内容と求人企業の求人条件の照合、キャリアコンサルタントによる面談・転職相談、求人案件の紹介と転職支援活動、求人企業との折衝業務の代行（応募から入社まで）、その他利用者にとって有益と判断するサービスの総称です。
                </li>
                <li>
                  メールマガジン配信サービス <br />
                  転職に役立つ情報等をメールにより配信するサービスです。
                </li>
              </ol>
              <h2>第２条（利用者）</h2>
              <ol>
                <li>
                  利用者とは、本規約および「個人情報の取り扱いについて」の内容に同意の上、本サービスの利用を申し込み、かつ当社が本サービスの利用を承認した方をいいます。
                </li>
                <li>
                  利用者は、本サービスの利用を申し込んだ時点で本規約の内容および「個人情報の取り扱いについて」の内容をすべて承諾したものとみなします。
                </li>
                <li>
                  お預かりした個人情報が不足している場合、本サービスを提供できない場合があります。
                </li>
              </ol>
              <h2>第３条（本サービスの利用申し込みと審査）</h2>
              <ol>
                <li>
                  当社は、本サービス利用のお申し込みをされた方について審査を行うことがあり、その際は、当社が承認した場合にのみ、本サービスの利用申し込みが完了します。
                </li>
                <li>
                  当社は、本サービス利用のお申し込みをされた方について、サービスを提供することが不適切と判断した場合、いつでもその利用をお断りすることができるものとします。また、その判断に関する質問等への回答および、その他一切の対応を行う義務はないものとします。
                </li>
                <li>
                  前各項に掲げる内容から発生する一切の損害について、当社は何らその責任を負わないものとします
                </li>
              </ol>
              <h2>第４条（個人情報の取り扱い）</h2>
              <p>
                当社は、別途定める「個人情報の取り扱いについて」に従い、利用者の個人情報を適切に収集・利用・提供・管理いたします。
              </p>
              <h2>第５条（求人の照合）</h2>
              <p>
                当社は、利用者の希望やお申し込み内容と求人企業が希望する求人条件とを照合しますが、その検討基準や判断の根拠・理由などをお伝えすることはできません。
              </p>
              <h2>第６条（利用者の禁止事項）</h2>
              <p>
                利用者は、本サービスの利用申し込み時、および本サービスを利用する際に、以下の行為をしてはならないものとします。
              </p>
              <ol>
                <li>虚偽の情報を申告・提供する行為</li>
                <li>
                  当社および当社の事業に関わる関係者や他の利用者または第三者を誹謗・中傷、侮辱する行為
                </li>
                <li>
                  当社および当社の事業に関わる関係者や他の利用者または第三者の財産、名誉、プライバシーや著作権、商標権、その他の知的所有権を含む一切の権利を侵害する行為
                </li>
                <li>
                  本サービスの運営を妨げる行為もしくは当社の社会的信用・評価を毀損する行為
                </li>

                <li>本サービスで取得した情報を第三者に漏洩・開示する行為</li>
                <li>公序良俗や法令に違反する行為、またはその恐れがある行為</li>
                <li>
                  非合法的あるいは反社会的勢力等に関連する組織に属する行為、それら勢力に利益を与える行為、またはそれら勢力を利用する行為等、もしくはそれら勢力による被害の恐れのある行為
                </li>
              </ol>
              <h2>第７条（本サービスの提供の停止・終了）</h2>
              <ol>
                <li>
                  利用者が本規約に違反したと当社が判断した場合、または、利用者と当社との信頼関係が維持できないと当社が判断した場合、当社は当該利用者に何らの催告を要することなく、即時に本サービスの提供を停止または終了することができるものとします。これにより発生する一切の損害について、当社は何らその責任を負わないものとします。なお、本項の定めは、当社の当該利用者に対する損害賠償請求を妨げるものではありません。
                </li>
                <li>
                  前項の場合において、当社はその判断に関する質問等への回答および、その他一切の対応を行う義務はないものとします。
                </li>
              </ol>
              <h2>第８条（本サービスの変更・中断）</h2>
              <p>
                当社は、以下のいずれかに該当する場合は、利用者への通知・承諾なく本サービスの全部もしくは一部を変更、または一時中断することがあります。
              </p>

              <ol>
                <li>
                  本サービスを提供するためのシステム等の保守、点検、更新、その他緊急の必要性がある場合
                </li>
                <li>
                  通常の安全対策では防止が不可能なシステム障害やウイルス被害、地震・火災・停電等不可抗力により本サービスの提供が困難になった場合
                </li>
                <li>
                  その他不測の事態により、本サービスの提供が困難と当社が判断した場合
                </li>
              </ol>
              <h2>第９条（利用者の責任と免責事項）</h2>
              <ol>
                <li>
                  本サービスの利用は、利用者が自己の責任に基づいて行うものとします。
                </li>
                <li>
                  利用者は、本サービスの利用に際し、当社に提供した情報について一切の責任を負うものとします。
                </li>
                <li>
                  利用者の本サービスの利用が、必ず就職や転職に成功する等の有効性を保証するものではありません。
                </li>
                <li>
                  利用者は、利用者の責任において、入社後の仕事内容や労働条件を直接求人企業に確認し解雇自由、離職自由の原則に基づく雇用（At-will
                  Employment）に入るものとします。当社は求人企業に確認を行なうものの当該雇用内容を最終的に保証するものではありません。
                </li>
                <li>
                  当社は、本サービスを利用したことにより利用者に損害が発生した場合には、当社の過失により直接的かつ通常生じる範囲内の損害に限り責任を負い、その他の損害については責任を負いません。
                </li>
              </ol>
              <h2>第10条（損害賠償）</h2>
              <p>
                利用者が本サービスの利用に際し、当社および当社の関係者または第三者に対し損害を与えた場合、利用者はその損害を賠償し、当社を免責するものとします。
              </p>
              <h2>第11条（本規約の変更）</h2>
              <p>
                当社は、必要に応じて利用者の承諾を得ることなく本規約の内容を変更することができるものとします。
              </p>
              <h2>第12条（定めのない事項）</h2>
              <p>
                本規約に定めのない事項は、利用者と当社との間において、互いに誠意をもって協議し解決することとします。
              </p>
              <h2>＜個人情報の取り扱いについて＞</h2>
              <p>
                当社では、お預かりした個人情報の取り扱いについて、下記の通り管理・運用を行っております。
              </p>
              <h3>【個人情報の定義】</h3>
              <p>
                個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、住所、職務経歴をはじめ電話番号、e-mailアドレスなどやそれらの組み合わせにより当該個人と識別できるものを個人情報として取り扱います。
              </p>

              <h3>【個人情報収集の目的】</h3>
              <p>
                当社が適切な転職支援サービスを提供することを目的として個人情報を収集します。
                ただし、当社サービスの向上を目的としたアンケート等のお願いをする場合に、利用させていただく場合がございます。
              </p>
              <h3>【個人情報の利用および提供について】</h3>
              <p>
                お預かりした個人情報は「個人情報収集の目的」の範囲内にて取り扱います。
                利用者の同意がない限り、個人情報を企業および第三者に開示・提供することは一切ありません。
              </p>
              <h3>【グループ会社への個人情報の提供について】</h3>
              <p>
                当社は、本サービスの遂行のための必要な場合に限り、個人情報をグループ会社に提供することがあります。提供の実施にあたっては、個人情報の保護に関する法律（個人情報保護法）その他の関係法令等を遵守して、個人情報を取り扱います。
              </p>

              <ol>
                <li>
                  グループ会社に提供される個人データの項目は以下となります
                  氏名、住所、学歴、職務経歴等
                </li>
                <li>
                  グループ会社への提供の手段又は方法は以下となります
                  ①電話、②メール、③郵便、④書面、⑤口頭
                </li>
                <li>利用者の求めに応じてグループ会社への提供を停止します</li>
              </ol>
              <h3>【個人情報の管理について】</h3>
              <p>
                個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩などの危険に対して、組織面、施設・設備面及び技術面おいて必要かつ合理的な安全対策を継続的に講じるよう努めます。
              </p>
              <h3>【管理者】</h3>
              <p>
                当社は、管理部門責任者を個人情報の管理者とし、適切な個人情報の保護に努めます。
              </p>
              <h3>【外部預託】</h3>
              <p>
                システムの改善・保守や情報処理の業務を外部に委託する際に、個人情報の取り扱い業務を預託する場合がございます。機密保持契約を締結した上で個人情報の取り扱い業務の預託を行います。
              </p>
              <h3>【個人情報の保管期間】</h3>
              <p>
                当社が判断する適切な期間を経たのち、個人情報保護の観点に基づき、利用者の個人情報を削除します。なお履歴書および職務経歴書等、当社へ郵送および持参いただいた書類は、企業紹介・推薦の有無、採用・不採用の結果を問わず返却しません。個人情報の削除と同時に当社が責任を持って廃棄します。
              </p>
              <h3>【個人情報の訂正・開示・削除（利用停止）について】</h3>
              <p>
                お預かりした個人情報について、訂正・開示・削除（利用停止）のお申し出があった場合、利用者本人であることを確認でき次第、すみやかに対応いたします。ただし、当社の業務に支障のある場合や、連邦法および州法に基づく業務記録保持の必要がある場合は、訂正、追加または削除（利用停止）の依頼にお応えできないことがあります。
              </p>
              <h3>【本規約の変更】</h3>
              <p>
                当社は、利用者の承諾なしに必要に応じて「個人情報の取り扱いについて」の内容を変更することができるものとします。
              </p>
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default TermsOfUse
